<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  components: { },
  data: function(){
    
  },
  methods: {
    
  }
}
</script>

<style>

</style>
