import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    redirect: '/login',
  },
 // { path: '/register', name: 'register', component:() =>import ('../views/RegisterView.vue'),meta: { title: '注册'} },
  { path: '/login', name: 'login', component: () => import('../views/LoginView.vue'),meta: { title: '登录'} },
  { path: '/link', name: 'link', component: () => import('../views/LinkView.vue'),meta: { title: '短链接生成系统'} },
]

const router = new VueRouter({
  routes
})


export default router
